import { Component } from "@ixperientialmedia/circular";

export class StartComponent extends Component {
    static get component() {
        return {
            selector: 'start-component',
            templateUrl: 'components/start/start.component.html',
            styleUrls: ['components/start/start.component.css']
        }
    }
}
