import { Component } from "@ixperientialmedia/circular";

export class StandbyComponent extends Component {
    static get component() {
        return {
            selector: 'standby-component',
            templateUrl: 'components/standby/standby.component.html',
            styleUrls: ['components/standby/standby.component.css']
        }
    }
}
