import { Component } from "@ixperientialmedia/circular";
import { Subscription } from "rxjs";
import { sessionManagerService } from "../../services/session-manager.service";

export class CodeScreenComponent extends Component {
    static get component() {
        return {
            selector: 'code-screen-component',
            templateUrl: 'components/code-screen/code-screen.component.html',
            styleUrls: ['components/code-screen/code-screen.component.css']
        }
    }

    constructor() {
        super();

    }
 
    onAfterViewInit() {
        if (window.location.search != '?tethered'){
            const url = "/resources/api/code";
            window.open(url, '_self');
        }
    }

    onDetached() {
        
    }

}
