import { Component } from "@ixperientialmedia/circular";
import { filter, Subscription } from "rxjs";
import { gameService } from "../../services/game.service";
import { sessionManagerService } from "../../services/session-manager.service";

export class UserInfoComponent extends Component {
    static get component() {
        return {
            selector: 'user-info-component',
            templateUrl: 'components/user-info/user-info.component.html',
            styleUrls: ['components/user-info/user-info.component.css']
        }
    }

    constructor() {
        super();
        this.inputFields = [
            {
                text:"First Name",
                id:"firstName",
                rule:"^[a-zA-Z ]{2,30}$",
                inError: false,
                value: null
            },
            {
                text:"Last Name",
                id:"lastName",
                rule:"^[a-zA-Z ]{2,30}$",
                inError: false,
                value: null
            },
            {
                text:"Email",
                id:"email",
                rule:"(.+)@(.+){2,}\.(.+){2,}",
                inError: false,
                value: null
            },
        ];

        this.roles = [
            "Veterinarian",
            "Vet Technician",
            "Practice Manager",
            "Support Staff",
            "Vet Student",
            "Other (Guests, Exhibitors or Press/PR)"
        ];
    }

    onAfterViewInit() {
        this.code = null;
        this.role = null;
        this.consent = false;
        this.selected = "";
        this.subscribes = new Subscription();
        this.errorText = "";
        this.selectRole = false;
        this.codeError = "";

        this.subscribes.add(
            sessionManagerService.joinResult$.pipe(
                filter(x => x.status === "failed")
            ).subscribe(x => {
                // failed to connect, show error message
                console.log(`Failed to connect. Reason: ${x.reason}`);
                this.codeError = "Please Check the Room Code matches the Big Screen";
            })
        )
    }

    onDetached() {
        this.subscribes.unsubscribe();
    }

    onInputFieldChanged(newValue, inputFieldIndex) {
        this.inputFields[inputFieldIndex].value = newValue;
        const isValid = this.validateInputField(inputFieldIndex);
        if (!isValid) {
            console.log(`Invalid value for field ${inputFieldIndex}`);
            this.inputFields[inputFieldIndex].inError = true;
            this.inputFields[inputFieldIndex].value = null;
        } else {
            this.inputFields[inputFieldIndex].inError = false;
        }

        console.log(this.inputFields[inputFieldIndex].value)
    }

    validateAllFields() {
        let allValid=true;

        if (this.code === null) {
            allValid = false;
            this.codeError = "Please Enter a Game Code";
        }else{
            this.codeError = "";
        }
        
        /*
        if (this.role === null) {
            allValid = false;
            this.selectRole = true;
            this.errorText = "Please Select a Role From the Menu";
        }else{
            this.selectRole = false;
        }

        for (let index = 0; index < this.inputFields.length; index++) {
            if (!this.validateInputField(index)) {
                this.inputFields[index].inError = true;
                allValid = false;
                this.errorText = "Please check your First Name or Last Name";
            }
        }

        // Split this error message out since it's more likely to be the problem.
        if (this.inputFields[2].inError){
            this.errorText = "Please check your Email Address";
        }


        if (this.consent === false) {
            allValid = false;
            this.errorText = "Please Check the Third Party Policy Consent Box";
        }
        */
        return allValid;
    }

    validateInputField(inputFieldIndex) {
        const regex = new RegExp(this.inputFields[inputFieldIndex].rule);
        const value = this.inputFields[inputFieldIndex].value;

        if (value == null){
            return false;
        }

        return regex.test(value);
    }

    onCodeComplete(code) {
        this.code = code;
    }

    onRolePicked(roleIndex) {
        if (roleIndex > 0) {
            this.role = this.roles[roleIndex-1];
        } else {
            this.role = null;
        }
    }

    onConsentChanged(consent) {
        this.consent = consent;
    }

    onSubmit() {
        const allValid = this.validateAllFields();
        if (allValid) {
            this.errorText = "";
            const firstName = this.inputFields.find(x=>x.id === "firstName" ).value
            const lastName = this.inputFields.find(x=>x.id === "lastName" ).value
            const email = this.inputFields.find(x=>x.id === "email" ).value
            sessionManagerService.sendUserInfo(firstName, lastName, email, this.role, this.code);
        } else {
            console.log("Not all fields are valid");
        }

    }

    onMouseDown(){
        this.selected = "selected";
    }

    onMouseUp(){
        this.selected = "";
    }
}
