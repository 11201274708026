import { Module } from "@ixperientialmedia/circular";
import { AppComponent } from "./components/app-component/app.component";
import { CategoryComponent } from "./components/category/category.component";
import { CodeScreenComponent } from "./components/code-screen/code-screen.component";
import { PinCodeComponent } from "./components/pin-code/pin-code.component";
import { QuestionComponent } from "./components/question/question.component";
import { ResultsComponent } from "./components/results/results.component";
import { RotateComponent } from "./components/rotate/rotate.component";
import { RoyalCheckboxComponent } from "./components/royal-checkbox/royal-checkbox.component";
import { SplashComponent } from "./components/splash/splash.component";
import { StandbyComponent } from "./components/standby/standby.component";
import { StartComponent } from "./components/start/start.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";

export class AppModule extends Module {
    static get module() {
        return {
            declarations: [
                AppComponent,
                UserInfoComponent,
                SplashComponent,
                PinCodeComponent,
                StartComponent,
                CategoryComponent,
                QuestionComponent,
                ResultsComponent,
                RoyalCheckboxComponent,
                CodeScreenComponent,
                StandbyComponent,
                RotateComponent
            ],
            imports: [
            ],
            styles: [
                "styles/common.css",
                "styles/fonts.css",
                "styles/variables.css",
            ],
            bootstrap: [
                AppComponent
            ]
        }
    }
}