import { Component } from "@ixperientialmedia/circular";
import { Subscription } from "rxjs";
import { gameService } from "../../services/game.service";

export class CategoryComponent extends Component {
    static get component() {
        return {
            selector: 'category-component',
            templateUrl: 'components/category/category.component.html',
            styleUrls: ['components/category/category.component.css']
        }
    }

    constructor() {
        super();
        this.categoryId = null;
        this.categoryName = null;
    }

    onAfterViewInit() {
        this.subscribes = new Subscription();
        this.subscribes.add(
            gameService.curretBeat$.subscribe(beat => {
                this.categoryId = beat.current_category_id;
                this.categoryName = beat.current_category_name;
            })
        );
    }

    onDetached() {
        this.subscribes.unsubscribe();
    }

}
