import { BehaviorSubject, combineLatest, filter, first, map, merge, timer, withLatestFrom } from "rxjs";
import { STATES_ENUM } from "../enums/states.enum.js";
import { sessionManagerService } from "./session-manager.service.js";
import { socketIOService } from "./socketio.service.js";


class GameService {
    constructor() {
        //this.curretBeat$ = socketIOService.playerBeat$;
        this.curretBeat$ = socketIOService.playerBeat$.pipe(
            filter(x => x !== null),
            map(beat => {
                if (beat.current_category_id === "START") {
                    beat.current_category_id = "CAT_FRIENDLY_CLINIC";
                    beat.current_category_name="Cat Friendly Clinic";
                }
                return beat;
            })
        );

        this.resetGame$ = socketIOService.left$;
        this.connected$ = socketIOService.connected$;

        this.currentState$ = new BehaviorSubject(STATES_ENUM.SPLASH);

        this.curretBeat$.pipe(
            map(x => {
                console.log(x);
                if ((x.current_category_id === "START") || (x.current_category_id === "ORIENTATION")) {
                    return STATES_ENUM.START;
                } else if (x.is_question) {
                    return STATES_ENUM.QUESTION;
                } else if (x.is_result) {
                    return STATES_ENUM.RESULTS;
                } else if (x.is_redemption) {
                    return STATES_ENUM.CODE;
                } else {
                    return STATES_ENUM.CATEGORY;
                }
            })
        ).subscribe(state => this.currentState$.next(state));

        //socketIOService.disconnected$.subscribe(_ => this.currentState$.next(STATES_ENUM.SPLASH));

        // Show splash while connecting
        socketIOService.connected$.pipe(
            withLatestFrom(this.currentState$)
        ).subscribe(([_, state]) => {
            if (state === STATES_ENUM.SPLASH) {
                this.currentState$.next(STATES_ENUM.USER_INFO);
            }
        });

        this.currentQuestion$ = combineLatest([
            this.curretBeat$,
            socketIOService.question$,
        ]).pipe(
            filter(([beat, question]) => (beat !== null) && (question !== null) && (beat.current_beat_id === question.question_id)),
            map(([_, question]) => question)
        )

        sessionManagerService.joinResult$.pipe(
            filter(x => x.status === "failed")
        ).subscribe(x => {
            // failed to connect, go back to user info
            this.currentState$.next(STATES_ENUM.USER_INFO);
        })
    }

    sendAnswer(question_id, answer_index) {
        let answer = {
            question_id: question_id,
            answer_index: answer_index
        }
        console.log(answer);

        socketIOService.send("answer", answer);
    }

    sendAnswers(question_id, answers) {
        let answer = {
            question_id: question_id,
            answers: answers
        }

        socketIOService.send("answer", answer);
    }
}

export const gameService = new GameService();