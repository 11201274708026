import { Component } from "@ixperientialmedia/circular";
import { Observable, Subscription, range, map, timer, take, first, filter, tap } from "rxjs";
import { gameService } from "../../services/game.service";
import { timerToString } from "../../utils/utils";

export class QuestionComponent extends Component {
    static get component() {
        return {
            selector: 'question-component',
            templateUrl: 'components/question/question.component.html',
            styleUrls: ['components/question/question.component.css']
        }
    }

    constructor() {
        super();

    }

    onAfterViewInit() {
        this.questionID = null;
        this.questionTitle = null;
        this.questionText = null;
        this.is_multiple_choice = false;
        this.is_pop_quizz = false;
        /**
         * @type {number|boolean[]}
         */
        this.userAnswer = null;
        this.userSelectedAnswered = false;
        this.userSubmittedAnswered = false;

        this.timeLeft = null;
        this.timeout = false;
        this.timer$ = null;
        this.answers = [];


        this.subscribes = new Subscription();
        this.subscribes.add(
            gameService.currentQuestion$.
            pipe(
                filter(question => (question !== null) && (question.question_id !==  this.questionID))
            ).subscribe(question => {
                this.questionID = question.question_id;
                this.questionTitle = question.player_question.question.title;
                this.questionText = question.player_question.question.question;

                this.is_multiple_choice = question.question_type.is_multiple_choice;
                this.is_pop_quizz = question.question_type.is_pop_quizz;

                this.answerStyles = [];
                this.answerStringStyles = [];
                this.userAnswer = null;

                if (question.player_question.answers) {
                    const answers = question.player_question.answers;

                    this.answerStyles = answers.map(x => {
                        return [
                            'answer-button',
                            x.text ? 'large-button' : ''
                        ].join(' ')
                    });
                    this.answers = answers;

                } else {
                    // transform from array of answers to objects
                    this.answers = question.answers.map(x => {
                        return {
                            title: x
                        };
                    });
                }

                if (this.is_multiple_choice) {
                    this.userAnswer = Array(this.answers.length).fill(false);
                }

                // start countdown
                const start = question.question_time;
                this.timeLeft = timerToString(start);
                if (this.timer$ !== null) {
                    this.timer$.unsubscribe();
                }
                this.timeout = false;
                this.timer$ = timer(1, 1000) // timer(firstValueDelay, intervalBetweenValues)
                        .pipe(
                            map(i => start - i),
                            take(start + 1)
                        )
                        .subscribe(i => {
                            this.timeLeft = timerToString(i);
                            if (i === 0) {
                                this.timeout = true;
                            }
                        })

            })
        );
    }

    onDetached() {
        this.subscribes.unsubscribe();
        if (this.timer$ !== null) {
            this.timer$.unsubscribe();
        }
    }

    onAnswer(index) {
        this.userAnswer = index;
        this.userSelectedAnswered = true;
        const answerButtons = this.getViewChild("answerButtons");

        for (const element of answerButtons.children) {
            element.classList.remove("selected");
        }

        answerButtons.children[index].classList.add("selected");
    }

    onAnswerCheckboxChanged(checked, index) {
        // maintain a state of all answered
        this.userAnswer[index] = checked;
        this.userSelectedAnswered = this.userAnswer.includes(true);
        console.log(this.userAnswer);
    }

    onSubmit() {
        gameService.sendAnswer(this.questionID, this.userAnswer);
        this.userSubmittedAnswered = true;
    }

    updateStyle(style, styleString) {
        styleString = style.answer + ' '
            + style.buttonType + ' '
            + style.selected;
    }
}
