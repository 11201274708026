import { Component } from "@ixperientialmedia/circular";
import { combineLatest, fromEvent, Subscription, timer } from "rxjs";
import { STATES_ENUM } from "../../enums/states.enum.js";
import { gameService } from "../../services/game.service.js";


export class AppComponent extends Component {
    static get component() {
        return {
            selector: 'app-component',
            templateUrl: 'components/app-component/app.component.html',
            styleUrls: ['components/app-component/app.component.css']
        }
    }

    constructor() {
        super();
        // we do this to be able to use the enum within our bindings
        this.STATES_ENUM = STATES_ENUM;
        /**
         * @type {STATES_ENUM}
         */
        this.state = null;
        this.needsRotate = false;
        this.connectedToSocket = false;
    }

    resetGame() {
        this.state = STATES_ENUM.USER_INFO;
    }

    onAfterViewInit() {
        this.state = STATES_ENUM.SPLASH;
        this.subscriptions = new Subscription();

       
        // Confirm screen size on start.
        this.resizeDetected();

        this.subscriptions.add(
            fromEvent(window, 'resize').subscribe(event => this.resizeDetected())
        );

        this.subscriptions.add(
            // show the splash for at least three seconds before showing the correct component
            combineLatest([
                timer(3000),
                gameService.currentState$
            ])
            .subscribe(([_, state])=>this.state = state)
        );

        this.subscriptions.add(
            gameService.resetGame$.subscribe(reason=> {
                this.resetGame();
            })
        );

        this.subscriptions.add(
            gameService.connected$.subscribe(connected=> {
                this.connectedToSocket = connected;
            })
        )
    }

    onDetached() {
        this.subscriptions.unsubscribe();
    }

    resizeDetected(){
        if (window.innerWidth / window.innerHeight  > 1.3333){
            this.needsRotate = true;
        }
        else{
            this.needsRotate = false;
        }
    }
    
    
}