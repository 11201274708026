import * as axios from 'axios';
import { Observable } from 'rxjs';

class RequestService {
    constructor() {
        this.requestEndpoint = "/resources/api/register"
    }

    sendUserData(userData) {
        userData.key = "56kebRtuvWGnUCodiOAdt365Y014";
        return Observable.create((observer) => {
            axios
                .post(this.requestEndpoint, userData)
                .then(res => {
                    observer.next(res.data);
                    observer.complete();
                })
                .catch(error => {
                    console.error(error);
                    observer.error(error);
                })
        });
    }
}

export const requestService = new RequestService();