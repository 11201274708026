import { Component } from "@ixperientialmedia/circular";

export class PinCodeComponent extends Component {
    static get component() {
        return {
            selector: 'pin-code-component',
            templateUrl: 'components/pin-code/pin-code.component.html',
            styleUrls: ['components/pin-code/pin-code.component.css']
        }
    }

    constructor() {
        super();
        this.inputsIndices = [0, 1, 2, 3];
        
    }

    onAfterViewInit() {
        this.reset();
    }

    onInputClick(elementIndex) {
        console.log(elementIndex);
        const inputs = this.getViewChildren("inputs");
        this.reset();
        if (elementIndex !== 0) {
            // set focus on first element
            inputs[0].focus();
        }
    }

    onInputChanged(elementIndex) {
        console.log(elementIndex);

        const inputs = this.getViewChildren("inputs");
        console.log(inputs);

        const input = inputs[elementIndex];
        if (input.value) {
            if (elementIndex === (this.inputsIndices.length - 1)) {
                const userCode = [...inputs].map(input => input.value).join('');
                this.dispatchEvent(new CustomEvent("codeChanged", {'detail':userCode}));
            } else {
                inputs[elementIndex + 1].focus();
            }
        }
    }

    reset() {
        const inputs = this.getViewChildren("inputs");
        // clear values
        inputs.forEach(element => {
            element.value = '';
        });
        this.dispatchEvent(new CustomEvent("codeChanged"));
    }


}
