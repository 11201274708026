import { Component } from "@ixperientialmedia/circular";

export class ResultsComponent extends Component {
    static get component() {
        return {
            selector: 'results-component',
            templateUrl: 'components/results/results.component.html',
            styleUrls: ['components/results/results.component.css']
        }
    }
}
