import { Component } from "@ixperientialmedia/circular";
import { Subscription, timer } from "rxjs";

export class SplashComponent extends Component {
    static get component() {
        return {
            selector: 'splash-component',
            templateUrl: 'components/splash/splash.component.html',
            styleUrls: ['components/splash/splash.component.css']
        }
    }

    onAfterViewInit() {
        this.stillConnecting = false;
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            timer(3000).subscribe(_ => this.stillConnecting = true)
        )
    }

    onDetached() {
        this.subscriptions.unsubscribe();
    }
}
