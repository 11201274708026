import { Component } from "@ixperientialmedia/circular";
import { Subscription } from "rxjs";
import { gameService } from "../../services/game.service";

export class RotateComponent extends Component {
    static get component() {
        return {
            selector: 'rotate-component',
            templateUrl: 'components/rotate/rotate.component.html',
            styleUrls: ['components/rotate/rotate.component.css']
        }
    }

    constructor() {
        super();
    }

    onAfterViewInit() {

    }

}
