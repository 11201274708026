import { Component } from "@ixperientialmedia/circular";

export class RoyalCheckboxComponent extends Component {
    static get component() {
        return {
            selector: 'royal-checkbox-component',
            templateUrl: 'components/royal-checkbox/royal-checkbox.component.html',
            styleUrls: ['components/royal-checkbox/royal-checkbox.component.css']
        }
    }

    constructor() {
        super();
        
        this.imgUp = "assets/images/check_box_up.png";
        this.imgDn = "assets/images/check_box_dn.png";
        this.imgCur = this.imgUp;
    }

    onAfterViewInit() {
        this.reset();
    }

    onClicked() {
        this.isChecked = !this.isChecked;
        this.imgCur = this.isChecked ? this.imgDn : this.imgUp;

        this.dispatchEvent(new CustomEvent("checkChanged", {'detail': this.isChecked}));
    }

    reset() {
        // clear values
        this.isChecked = false;
        this.imgCur = this.imgUp;
        //this.dispatchEvent(new CustomEvent("checkChanged", {'detail': this.isChecked}));
    }
}
