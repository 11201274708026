import { BehaviorSubject, combineLatest, distinctUntilChanged, first, fromEvent, Observable, ReplaySubject, shareReplay, skip, Subject, switchMap, take, takeUntil, tap, timer } from "rxjs";
import { io } from "socket.io-client";


class SocketIOService {
    constructor() {

        this.playerBeat$ = new BehaviorSubject(null);
        this.question$ = new BehaviorSubject(null);
        this.left$ = new Subject();
        

        this._connected$ = new BehaviorSubject(false);
        this._heartbeat$ = new Subject();
        this._connectedAndHeartBeat$ = new BehaviorSubject(false);

        this.connected$ = this._connectedAndHeartBeat$.pipe(distinctUntilChanged());



        this.socket = io();

        this.socket.on('connect', () => {
            this._connected$.next(true);
            // connected state is managed by heart beats
        });

        this.socket.on("disconnect", () => {
            this._connected$.next(false);
            // connected state is managed by heart beats
        });

        this.socket.on('state', (state) => {
            this.playerBeat$.next(state);
        });

        this.socket.on('question', (question) => {
            this.question$.next(question);
        });

        this.socket.on('left', (reason) => {
            this.left$.next(reason);
        });

        this.socket.on('heartbeat', () => {
            this._heartbeat$.next();
        });


        combineLatest([
            this._connected$,
            this._heartbeat$
        ]).pipe(
            tap(([connected,_]) => this._connectedAndHeartBeat$.next(connected)),
            switchMap(() => timer(3000).pipe(
                takeUntil(this._heartbeat$.pipe(skip(1))),
                take(1)
            ))
        ).subscribe(() => {
            // we haven't received a heartbeat for 3 seconds, something is wrong
            console.log("disconnected");
            this._connectedAndHeartBeat$.next(false);
        });
    }

    send(message, command, callback) {
        this.socket.emit(message, command, callback);
    }

}

export const socketIOService = new SocketIOService();