import { BehaviorSubject, filter, Subject, withLatestFrom } from "rxjs";
import { socketIOService } from "./socketio.service.js";
import { requestService } from "./request.service.js";

class SessionManagerService {
    constructor() {
        /**
         * @type {Subject<{status:string, reason:string}}
         */
        this.joinResult$ = new Subject();


        /**
         * @type {Subject<{first_name:string, last_name:string, game_code:string}}
         */
        this.currentSession$ = new Subject();
        // Comment / uncomment for debug (and set correct code)
        /*this.currentSession$ = new BehaviorSubject({
            first_name: "test",
            last_name: "test",
            game_code: "7843",
        });*/
        /*let playerInfo = {
            first_name: "test",
            last_name: "lastName",
            game_code: "gameCode",
            email_address: "emailAddress@asdflk.com",
            role: "Veterinarian",
            tethered: "false",
            clinic: "clinic"
        }
        requestService.sendUserData(playerInfo).subscribe(data => {
            console.log(data);
        });*/

        this.sessionStorage = window.sessionStorage;


        this.currentSession$.subscribe(session => {
            if (session === null) {
                this.clearSessionFromStorage();
            } else {
                this.setSessiontoStorage(session);
            }
        })

        socketIOService.connected$.pipe(
            withLatestFrom(this.currentSession$),
            filter(([connected, session]) => (connected === true) && (session !== null))
        ).subscribe(([_, session]) => {
            console.log("Auto-joining game.")
            this.connectToGameRoom(
                session.first_name,
                session.last_name,
                session.game_code,
                session.player_id
            )
        });

        socketIOService.left$.subscribe(_ => {
            this.clearSessionFromStorage();
        })

        this.retrieveSessionFromStorage();
    }

    retrieveSessionFromStorage() {
        const playerInfo = this.sessionStorage.getItem('player_info');
        if (playerInfo !== null) {
            try {
                this.currentSession$.next(JSON.parse(playerInfo));
            } catch (error) {
                console.log(`Error while parsing user data ${error}. Clearing sessions`);
                this.clearSessionFromStorage();
            }
            
        }
    }

    clearSessionFromStorage() {
        this.sessionStorage.removeItem('player_info');
        this.sessionStorage.removeItem('user_id');
    }

    setSessiontoStorage(session) {
        this.sessionStorage.setItem('player_info', JSON.stringify(session));
    }

    retrieveUserIdFromStorage(){
        return this.sessionStorage.getItem('user_id');
    }
    
    setUserIdtoStorage(userID) {
        this.sessionStorage.setItem('user_id', userID);
    }

    sendUserInfo(firstName, lastName, emailAddress, role, gameCode) {
        let playerInfo = {
            first_name: firstName,
            last_name: lastName,
            game_code: gameCode,
            email_address: emailAddress,
            role: role,
            tethered: (window.location.search == '?tethered') ? 'true' : 'false',
            clinic: 'clinic'
        }
        
        requestService.sendUserData(playerInfo).subscribe(data => {
            this.setUserIdtoStorage(data);
        });

        this.connectToGameRoom(firstName, lastName, gameCode);
    }

    connectToGameRoom(firstName, lastName, gameCode, playerID) {
        let playerInfo = {
            first_name: firstName,
            last_name: lastName,
            game_code: gameCode,
            player_id: playerID
        }

        socketIOService.send("join", playerInfo, (response) => {
            console.log(response);
            this.joinResult$.next(response);
            if (response.status === "success") {
                playerInfo.player_id = response.player_id;
                this.currentSession$.next(playerInfo);
            } else {
                this.currentSession$.next(null);
            }
        });
    }
}

export const sessionManagerService = new SessionManagerService();